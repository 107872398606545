require("@rails/ujs").start()
require("@rails/activestorage").start()

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery

import '../../assets/stylesheets/application'
import 'bootstrap/dist/js/bootstrap'

const images = require.context('../images', true)

import { DirectUpload } from "@rails/activestorage"
